(function ($) {

  $.fn.easyForm = function (options = {}) {

    const {
      wrapperClass = 'field-wrapper',
        tooltipClass = 'tooltips',
        resetForm = true,
        errorClass = 'is-invalid',
        nonFieldErrorClass = 'is-invalid',
        nonFieldErrorId = 'non-field-error',
        nonFieldErrorPlacement = function (error, form) {
          form.prepend(error);
        },
        beforeResetForm = function () {},
        errorPlacement = function (error, field) {
          field.parents('.' + wrapperClass).append(error);
        },
        noneFieldError = false,
        validators = false,
        submitButton = '[type="submit"]',
        googleCaptcha2 = false,
        validate = function () {},
        beforeSend = function () {},
        success = function (form) {},
        rejected = function () {},
        error = function () {},
        collectData = function (form) {
          return new FormData(form[0]);
        },
    } = options;

    return this.each(function () {
      var ths = $(this);

      ths.find('input:not([type="hidden"], [type="checkbox"], [type="radio"]), textarea')
        .on('input', function () {
          const field = $(this);
          const wrapper = $(this).parents('.' + wrapperClass);
          wrapper.removeClass(errorClass);
          deleteTooltip(field);
        });

      function onSubmit() {
        const form = ths;
        const button = form.find(submitButton);
        const url = form.attr('action');
        const method = form.attr('method') || 'POST';
        let data = collectData(form);

        if (validation(form)) {
          beforeSend();
          purgeErrors(form);
          button.prop('disabled', true);
          sendAjax();
        }

        function sendAjax() {
          $.ajax(url, {
            method,
            data,
            processData: false,
            contentType: false,
            success(result) {
              if (googleCaptcha2) {
                grecaptcha.reset(ths.data('recaptcha-widget-id'));
              }
              if (result.status === 'success') {
                clearForm(form);
                success(form);
              } else {
                if ('__all__' in result.errors) {
                  const message = result.errors['__all__'];
                  const error =
                    '<div class="' + nonFieldErrorClass + '" id="' + nonFieldErrorId + '">' + message + '</div>';
                  nonFieldErrorPlacement($(error), form);
                  delete result.errors['__all__'];
                }
                for (const errorName in result.errors) {
                  let field = form.find('[name="' + errorName + '"]');
                  setErrorField(field, result.errors[errorName][0]);
                }
                error();
              }
              enableButton(form);
            },
            error: function () {
              if (googleCaptcha2) {
                grecaptcha.reset(ths.data('recaptcha-widget-id'));
              }
              rejected();
              enableButton(form);
            }
          });
        }
      }

      if (googleCaptcha2) {
        const publicKey = ths.data('recaptcha-public-key');
        grecaptcha.ready(() => {
          const widgetId = grecaptcha.render(ths.find('.g-recaptcha')[0], {
            'sitekey': publicKey,
            'callback': onSubmit,
          });
          ths.data('recaptcha-widget-id', widgetId);
        })
      }

      ths.on('submit', function (e) {
        e.preventDefault();
        if (googleCaptcha2) {
          grecaptcha.execute(ths.data('recaptcha-widget-id'));
        } else {
          onSubmit();
        }
      });

    });

    function purgeErrors(form) {
      form.find('input:not([type="hidden"], [type="checkbox"], [type="radio"]), textarea')
        .each(function () {
          const field = $(this);
          const wrapper = $(this).parents('.' + wrapperClass);
          wrapper.removeClass(errorClass);
          deleteTooltip(field);
        });
      form.find('#' + nonFieldErrorId).remove();
    }

    function validation(form) {
      let errors = false;
      const values = {};

      form.find('input:not([type="hidden"], [type="checkbox"], [type="radio"]), textarea').each(function () {
        const field = $(this);
        const value = field.val();
        const fieldName = field.attr('name');
        values[fieldName] = value;

        if (validators) {

          if (validators[fieldName]) {
            for (const method in validators[fieldName]) {
              const func = validators[fieldName][method];
              if (func(value)) {
                setErrorField(field, func(value));
                errors = true;
                return;
              }
            }
          }
        }

      });

      const validateResult = validate(values);

      if (validateResult) {
        for (fieldName in validateResult) {
          const field = form.find('[name="' + fieldName + '"]');
          setErrorField(field, validateResult[fieldName]);
        }
        errors = true;
      }

      return !errors;
    }

    function isEmpty(field) {
      let value = field.val();
      value = (value || '').trim();
      return (value === '');
    }

    function isMin(field) {
      const value = field.val();
      const minLength = field.attr('minlength');
      if (minLength) {
        return value.length < +minLength;
      }
    }

    function isRequired(field) {
      return (field.attr('required'))
    }

    function createTooltip(field, message) {
      const tooltip = '<div class="' + tooltipClass + '">' + message + '</div>';
      errorPlacement($(tooltip), field);
    }

    function classesToSelector(classes) {
      return '.' + classes.split(' ').join('.');
    }

    function deleteTooltip(field) {
      const tooltip = field.parents('.' + wrapperClass).find(classesToSelector(tooltipClass));
      tooltip.remove();
    }

    function setErrorField(field, message) {
      const wrapper = $(field).parents('.' + wrapperClass);
      wrapper.addClass(errorClass);
      createTooltip(field, message);
    }

    function clearForm(form) {
      if (resetForm) {
        beforeResetForm();
        form.trigger('reset');
        form.find('input, textarea').removeClass('is-valid is-invalid');
        if (noneFieldError) {
          $(noneFieldError).text();
        }
      }
    }

    function enableButton(form) {
      const button = form.find(submitButton);
      button.prop('disabled', false);
    }
  };
})(jQuery);
